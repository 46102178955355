import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { List, ListItem, ListItemText, Box, Typography, LinearProgress, Collapse, Button } from '@mui/material';
import { supabase } from '../supabaseClient';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const History = () => {
  const { user } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [expandedThreadId, setExpandedThreadId] = useState(null);
  const [transcript, setTranscript] = useState({});
  const [batteryData, setBatteryData] = useState([]);

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;

        if (!token) {
          throw new Error("User is not authenticated");
        }

        const threadResponse = await axios.get(`${API_BASE_URL}/threads/list_threads/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        setThreads(threadResponse.data);
      } catch (error) {
        console.error('Error fetching threads:', error);
      }
    };

    const fetchBatteryData = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;

        if (!token) {
          throw new Error("User is not authenticated");
        }

        const response = await axios.get(`${API_BASE_URL}/device_power/power_info?start_time=` + new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(), {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        setBatteryData(response.data.readings.reverse());
      } catch (error) {
        console.error('Error fetching battery data:', error);
      }
    };

    if (user) {
      fetchThreads();
      fetchBatteryData();
    }
  }, [user]);

  const handleThreadClick = async (threadId) => {
    console.log(`Thread clicked: ${threadId}`); // Debugging line

    if (expandedThreadId === threadId) {
      setExpandedThreadId(null);
      return;
    }

    try {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;

      if (!token) {
        throw new Error("User is not authenticated");
      }

      const transcriptResponse = await axios.get(`${API_BASE_URL}/threads/get_thread/${threadId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      console.log(`Transcript fetched for thread ${threadId}:`, transcriptResponse.data); // Debugging line

      setTranscript(prevTranscript => ({
        ...prevTranscript,
        [threadId]: transcriptResponse.data.interactions
      }));
      setExpandedThreadId(threadId);
    } catch (error) {
      console.error('Error fetching transcript:', error);
    }
  };

  const handleDeleteHistory = async () => {
    try {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
  
      console.log("Token:", token); // Check if the token is available
  
      if (!token) {
        throw new Error("User is not authenticated");
      }
  
      await axios.delete(`${API_BASE_URL}/interactions/delete_all_history/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      setThreads([]); // Clear threads after deletion
      setTranscript({}); // Clear transcript after deletion
    } catch (error) {
      console.error('Error deleting interaction history:', error);
    }
  };
  
  const formatLocalDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  const batteryLevels = batteryData.map(data => data.battery_level);

// Calculate hours ago
const hoursAgo = batteryData.map(data => {
  const hoursDifference = Math.floor((Date.now() - new Date(data.timestamp)) / (1000 * 60 * 60));
  return hoursDifference;
});

const chartData = {
  labels: hoursAgo,
  datasets: [
    {
      label: 'Battery Level (%)',
      data: batteryLevels,
      fill: false,
      borderColor: 'rgba(75,192,192,1)',
      tension: 0.1
    }
  ]
};

const options = {
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 10,
        maxRotation: 0,
        minRotation: 0,
      },
      title: {
        display: true,
        text: 'Hours Ago'
      }
    },
    y: {
      beginAtZero: true,
      max: 100
    }
  }
};


  const latestBatteryLevel = batteryLevels[batteryLevels.length - 1];

  return (
    <Box p={3}>
      <Typography variant="h6" gutterBottom>
        Battery Information
      </Typography>
      {batteryLevels.length > 0 && (
        <Box mb={4}>
          <Typography variant="body1" gutterBottom>
            Current Battery Level: {latestBatteryLevel}%
          </Typography>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={latestBatteryLevel} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(latestBatteryLevel)}%`}</Typography>
            </Box>
          </Box>
          <Line data={chartData} options={options} />
        </Box>
      )}
      <Typography variant="h6" gutterBottom>
        Interaction History
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDeleteHistory}
        style={{ display: 'block', marginTop: '20px' }}
      >
        Delete All History
      </Button>

      <List>
        {threads.map((thread) => (
          <Box key={thread.thread_id} mb={2}>
            <ListItem button onClick={() => handleThreadClick(thread.thread_id)}>
              <ListItemText
                primary={`Conversation from: ${formatLocalDate(thread.end_time)}`}
              />
            </ListItem>
            <Collapse in={expandedThreadId === thread.thread_id} timeout="auto" unmountOnExit>
              {transcript[thread.thread_id]?.map((interaction, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    primary={
                      <>
                        <strong>User:</strong> {interaction.user_input || "(no input)"}<br />
                        <strong>Response:</strong> {interaction.bot_response || "(no response)"}
                      </>
                    }
                    secondary={
                      <>
                        <strong>Start:</strong> {formatLocalDate(interaction.interaction_start_timestamp)}<br />
                        <strong>Finish:</strong> {formatLocalDate(interaction.interaction_finish_timestamp)}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </Collapse>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default History;
